/* Vizualização do scroll */
h6 {
    position: fixed;
    top: 50vh;
    left: 0;
}

@keyframes jumpImg {
    0% { transform: translateY(50%); }
    70% { transform: translateY(-10%); }
    100% { transform: translateY(0); }
}
.jumpImg {
    animation: jumpImg .7s ease-in-out;
    opacity: 1 !important;
}

@keyframes downToUp {
    0% { transform: translateY(50%); }
    100% { transform: translateY(0); }
}
.downToUp {
    animation: downToUp .3s ease-in-out;
}

@keyframes upToDown {
    0% { transform: translateY(-50%); }
    100% { transform: translateY(0%); }
}
.upToDown {
    animation: upToDown .3s ease-in-out;
}

@keyframes leftToRight {
    0% { 
        transform: translateX(-50%); 
        opacity: 0; 
    }
    100% { 
        transform: translateX(0%); 
        opacity: 1; 
    }
}
.leftToRight {
    animation: leftToRight .5s ease-in-out;
    opacity: 1 !important;
}

@keyframes rightToLeft {
    0% { 
        transform: translateX(50%); 
        opacity: 0;
    }
    100% { 
        transform: translateX(0%); 
        opacity: 1;
    }
}
.rightToLeft {
    animation: rightToLeft .5s ease-in-out;
    opacity: 1 !important;
}